<template>
  <v-row class="text-center px-4 align-center mt-1" wrap>
    <v-col class="text-truncate" cols="12" md="2">
      共 {{ input.total }} 条
    </v-col>
    <v-col cols="12" md="6">
      <v-pagination
        v-model="options.page"
        @input="updatePage"
        @next="updatePage"
        @previous="updatePage"
        :length="Math.ceil(input.total / options.itemsPerPage)"
      >
      </v-pagination>
    </v-col>
    <v-col cols="6" md="2">
      <v-select
        dense
        hide-details
        :value="options.itemsPerPage"
        label="每页显示条数"
        @change="updateItemsPerPage"
        :items="perPageChoices"
      >
      </v-select>
    </v-col>
    <v-col cols="6" md="2">
      <v-text-field
        v-model="options.page"
        label="跳转到页码"
        type="number"
        hide-details
        dense
        @input="updatePage"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    input: {
      pageCount: "",
      total: "",
    },
  },
  data() {
    return {
      options: {
        page: 1,
        itemsPerPage: this.$store.getters.pageSize,
      },
      perPageChoices: [
        { text: "5 条/页", value: 5 },
        { text: "10 条/页", value: 10 },
        { text: "20 条/页", value: 20 },
        { text: "100 条/页", value: 100 },
      ],
    };
  },
  methods: {
    updatePage(page) {
      this.options.page = parseInt(page);
      this.$emit("output", this.options);
    },
    updateItemsPerPage(itemsPerPage) {
      this.options.itemsPerPage = parseInt(itemsPerPage);
      this.$emit("output", this.options);
    },
  },
};
</script>
<style></style>
