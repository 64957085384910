import { render, staticRenderFns } from "./organ.vue?vue&type=template&id=50975540&scoped=true&"
import script from "./organ.vue?vue&type=script&lang=js&"
export * from "./organ.vue?vue&type=script&lang=js&"
import style0 from "./organ.vue?vue&type=style&index=0&id=50975540&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50975540",
  null
  
)

export default component.exports